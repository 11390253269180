.plume-form_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
}
.plume-form_wrapper-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.plume-form_wrapper-buttons-manage {
  height: 46px;
  background-color: #eef4fd;
  border: 1px solid #eef4fd;
  border-radius: 32px;
  padding: 0px 12px;
  display: flex;
  align-items: center;
}
.plume-form_wrapper-buttons-manage button {
  min-width: inherit;
  padding: 0 12px;
}
.plume-form_wrapper-select_container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 20px;
}
.plume-image_container {
  display: flex;
  justify-content: center;
  padding-bottom: 0px;
  height: calc(100vh - 206px);
}
.plume-image_container .sub_notification {
  padding: 10px 20px;
  border-radius: 4px 4px;
  background-color: var(--white);
  position: absolute;
  z-index: 1;
}
.plume-image_container .sub_notification button {
  min-width: inherit;
  padding: 0 6px;
}
.plume-image_container-schema {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border);
  border-radius: 16px;
  width: 100%;
}
.plume-image_container-schema .schema-field-image-missing {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
}

 /*# sourceMappingURL=style.css.map */
.modal_full_screen {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal_full_screen-container {
  height: 100%;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_full_screen-modal {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(178, 182, 203, 0.32);
  border-radius: 12px;
}
.modal_full_screen-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 35px 10px;
}
.modal_full_screen-modal-header-title {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: var(--black);
}
.full_screen_icon-wrapper {
  display: flex;
  justify-content: space-between;
}
.full_screen_icon-wrapper .full_screen_icon {
  width: 40px;
  height: 45px;
  cursor: pointer;
  transition: .3s;
}
.full_screen_icon-wrapper .full_screen_icon:hover {
  color: var(--main);
  fill: var(--main);
  transition: .3s;
}

.more {
  width: 36px;
  height: 36px;

  cursor: pointer;
  background-size: contain !important;
  background: url("../../../../image/icons/sliderPlus.png") no-repeat;
}
.more:hover {
  background: url("../../../../image/icons/sliderPlus.png") no-repeat;
}
.less {
  width: 36px;
  height: 36px;

  cursor: pointer;
  background: url("../../../../image/icons/sliderMinus.png") no-repeat;
  background-size: contain !important;
}
.less:hover {
  background: url("../../../../image/icons/sliderMinus.png") no-repeat;
}
.back {
  width: 49px;
  height: 49px;
  margin-top: 2px;
  cursor: pointer;
  background: url("../../../../image/back.svg") no-repeat;
  background-size: contain !important;
  margin-bottom: 10px;
}
.back:hover {
  background: url("../../../../image/backhover.svg") no-repeat;
}
.list {
  width: 48px;
  height: 48px;
  cursor: pointer;
  background: url("../../../../image/icons/eventListOpen.png") no-repeat;
  background-size: contain !important;
}
.list:hover {
  background: url("../../../../image/icons/eventListOpen.png") no-repeat;
}
.list {
  width: 48px;
  height: 48px;
  cursor: pointer;
  background: url("../../../../image/icons/eventListOpen.png") no-repeat;
  background-size: contain !important;

}
.list:hover {
  background: url("../../../../image/icons/eventListOpen.png") no-repeat;
}
