.pay_box {
  width: 334px;
  height: 120px;
  background-image: linear-gradient(to right, #d7e7fc, #a4cef5);
  border-radius: 20px;
  padding: 16px;
}

.pay_box_upblock {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #acc5ea;
  padding-bottom: 18px;
}

.pay_box_form .input-wrapper {
  padding: 0px !important;
}

.pay_box_form .input-wrapper input {
  color: var(--mainText) !important;
}

.pay_box_label {
  color: #4d5c6e;
  font-size: 14px !important;
  line-height: 17px !important;
  font-weight: 500 !important;
}

.pay_box_balance {
  color: var(--mainText);
  font-size: 20px !important;
  font-weight: medium !important;
}

.pay_box_close {
  width: 18px;
  height: 18px;
  transition: .3s;
}

.pay_box_close:hover {
  color: var(--accent);
  transition: .3s;
}


.pay_box_min_summ {
  width: 197px;
  padding: 7px 0;
  border: 1px solid black;
  border-radius: 32px;
  color: #051d39;
  line-height: 16px !important;
  font-weight: medium;
  text-align: center;
}

