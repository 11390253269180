.add-controller-container {
  .add-controller-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 7px;

    &-form {
      display: flex;
      justify-content: space-between;

      &-buttons {
        display: flex;
        gap: 20px;
        margin-bottom: 25px;
      }
    }
  }
}