.objects-list {
    display: flex;
}

.add-object {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    min-width: 366px;
    color: var(--mainText) !important;
    border-radius: 20px;
    background-color: var(--white);
    cursor: pointer;

}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.object-list-column {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-left: 0;
    gap: 30px;
}