.rating {
  height: 45px;
  min-width: 450px;
  display: flex;
  gap: 10px;
  align-items: center;

  .rating-legend {
    font-family: var(--INTER);
    font-weight: 500;
    font-size: var(--standartLg);
    color: var(--black);
    text-align: start;
    line-height: 18px;
  }

  .rating-content {
    height: 10px;
    position: relative;
    width: 100%;
  }

  .rating-pointer {
    border: 10px solid transparent;
    border-bottom: 10px solid grey;
    width: 0px;
    top: 1px;
    margin-left: -9px;
    position: absolute;
    transition: left 1s;

    .rating-pointer-text {
      font-family: var(--INTER);
      font-weight: 500;
      font-size: var(--small);
      color: var(--black);
      position: absolute;
      left: -13px;
      top: 10px;
      width: 30px;
      text-align: center;
    }
  }
}
