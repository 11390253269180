.legal-entity {
  &-banner {
    padding: 12px 44px;
    background-color: var(--bgBanner);
    color: var(--violet);
    font-family: var(--INTER);
    font-weight: 400;
    font-size: var(--standartLg);
    line-height: 24px;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 7px;
    margin-bottom: 20px;

    &-buttons {
      display: flex;
      gap: 20px;
    }
  }
}
