.App {
  font-family: sans-serif;
  text-align: center;
}
.vis-group-level-unknown-but-gte1 {
  border: 1px solid whitesmoke;
  background-color: red;
}
.custom-timeline .vis-item.vis-range .vis-item-overflow {
  background-color: transparent !important;
}
.vis-item{
  height:40px
}
.vis-item.red {
  border-color: black;
  background-color: rgb(204, 9, 9);
  opacity: 0.9;
}

.vis-item.peach {
  border-color: rgb(223, 211, 255);
  background-color: rgba(255, 204, 204);
  opacity: 0.9;
}

.vis-item.mint {
  border-color: rgb(223, 211, 255);
  background-color: rgba(204, 255, 204);
  opacity: 0.9;
}
.vis-ltr .vis-label.vis-nested-group .vis-inner {
  padding-left: 0px;
}

.vis-item-content {
  width: 30px;
  height: 20px;
}
.vis-label.vis-nested-group.vis-group-level-unknown-but-gte1 {
  background: none;
}

.vis-item.aqua {
  border-color: rgb(223, 211, 255);
  background-color: rgba(204, 255, 255);
  opacity: 0.9;
}

.vis-item.lilac {
  border-color: rgb(223, 211, 255);
  background-color: rgba(230, 204, 255);
  opacity: 0.9;
}

.vis-item.lightblue {
  border-color: rgb(223, 211, 255);
  background-color: rgba(204, 204, 255);
}

.vis-labelset .vis-label {
  padding-left: 0px;
  height: 71px !important; 
}

.custom-group:hover {
  cursor: pointer;
}
.vis-label .vis-inner {
  height: 60px; /* Задайте необходимую высоту для меток */
}
.vis-panel.vis-top {
  border:0 !important;
  left:auto !important;
  position: fixed !important;
  top: 525px !important;
}
.vis-timeline{
  border:0 !important;
}
.moveable-item {
  cursor: s-resize; /* Курсор для элементов, которые можно перемещать */
}

.clickable-item {
  cursor: pointer !important; /* Курсор для элементов, по которым можно кликнуть */
}

.default-item {
  cursor: default !important; /* Курсор по умолчанию для элементов, которые нельзя перемещать */
}
div.vis-tooltip {
  position: ABSOLUTE;
  visibility: hidden;
  padding: 5px;
  white-space: nowrap;
  font-family: verdana;
  font-size: 14px;
  color: #000;
  background-color: #f5f4ed;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #808074;
  box-shadow: 3px 3px 10px rgba(0,0,0,.2);
  pointer-events: none;
  z-index: 5;
}
.tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid #eef4fd;
  padding: 10px;
  border-radius: 3px;
  z-index: 1000;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease; /* Плавный переход */
  opacity: 0.9; /* Полупрозрачный фон */
}
.red { color: red; }
.orange { color: orange; }
.yellow { color: yellow; }
.green { color: green; }
.vis-panel>.vis-content{
  transform: translateY(0) !important;
}
.vis-panel.vis-bottom{
  display: none;
}
.vis.timeline .item {

}
.vis-time-axis.vis-foreground{
  border-radius: 40px;
}
.vis-group {
  height: 60px !important; /* Задайте необходимую высоту для групп */
}
.vis-time-axis .vis-text{
  text-align: center;
  font-size: 16px;
  background-color: #EEF4FD;
  height:39px;
  align-content: center;
}
.vis-time-axis .vis-text::first-letter{
  text-transform: uppercase;
}
.vis-timeline .vis-item {
  /* Set the height of the item */
  height: 100%; /* Full height of group */
  line-height: normal; /* Align text vertically */
}