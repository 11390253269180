.magazine_finished_tasks_page .magazine_events-select_container-btn {
  height: 49px;
}
@media screen and (max-width: 450px) {
  .magazine_finished_tasks_page .magazine_events-select_container-btn {
    width: 100%;
    margin-right: 0;
  }
}
.magazine_finished_tasks_page .search_field {
  margin-bottom: 24px;
}
@media (max-width: 1375px) {
  .magazine_finished_tasks_page .search_field {
    margin-top: 10px;
  }
}
.magazine_finished_tasks_page .search_field .medium_container {
  max-width: 35%;
}
@media screen and (max-width: 600px) {
  .magazine_finished_tasks_page .search_field .medium_container {
    max-width: 100%;
  }
}/*# sourceMappingURL=style.css.map */