.news-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.news-list-container {
  display: flex;
  flex-direction: column;
  width: 64%;
  gap: 24px;
  padding: 32px;
  background-color: var(--white);
  border-radius: 20px;
}

.news-list-header {
  display: flex;
  justify-content: space-between;
}

.news-list-header p {
  font-size: 20px;
  color: var(--mainText);
  font-weight: 500;
}
.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Количество строк */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news-panel-container {
  display: flex;
  gap: 30px;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.news-panel-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.news-panel-delete {
  color: var(--error);
}

.news-panel-edit {
  width: 20px;
  height: 20px;
}

.news-panel-edit:hover {
  color: var(--accent);
}

.news-panel-btns-wrapper {
  display: flex;
  gap: 10px;
}

.news-panel {
  width: 407px;
  padding: 20px;
  flex-shrink: 0;
  border: 1px solid var(--border);
  border-radius: 20px;
}

.news-panel:hover {
  cursor: pointer;
}

.news-panel:hover .news-panel-open-icon {
  color: var(--accent);
  transition: 0.3s ease;
}

.news-panel-edit:hover .news-panel-open-icon {
  color: var(--mainText);
}

.news-panel-adress {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
  height: 20px;
  color: var(--headers);
  font-weight: 500;
}

.news-panel-adress p {
  display: flex;
  gap: 10px;
}

.news-panel-title {
  font-size: 20px;
  color: var(--mainText);
  font-weight: 500;
  margin: 0;
  margin-bottom: 16px;

  display: -webkit-box;
  -webkit-line-clamp: 2; /* Количество строк */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-panel-text {
  margin: 0;
  line-height: 19px;
  color: var(--headers);
}

.operative-info {
  display: flex;
  flex-direction: column;
  width: 36%;
  height: 280px;
  gap: 16px;
  padding: 32px;
  border-radius: 20px;
  background: linear-gradient(to top left, #a4cef5, #d7e7fc);
}

.operative-info-title {
  margin: 0;
  font-size: 20px;
  color: var(--mainText);
  font-weight: 500;
}

.operative-info-btn-container {
  display: flex;
  gap: 8px;
}

.operative-info-btn {
  width: 50%;
  height: 39px !important;
  padding: 10px 0 !important;
  border-radius: 100px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: none !important;
}

.operative-info-btn.gray {
  background-color: #eef4fd !important;
  color: var(--headers) !important;
}

.operative-info-btn.green {
  background-color: var(--succes) !important;
  color: var(--white) !important;
}

.operative-info-btn.orange {
  background-color: var(--orange) !important;
  color: var(--white) !important;
}

.operative-info-text {
  height: 100%;
  overflow-y: hidden;
  cursor: pointer
}

.operative-info-text > * {
  margin: 0;
  font-weight: 500;
  color: var(--mainText);
}

.operative-info-text p:first-child {
  display: flex;
  gap: 20px;
}

.operative-info-text p:first-child svg:hover {
  cursor: pointer;
}

.operative-info-content {
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Количество строк */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.operative-info-text p:first-child {
  margin-bottom: 16px;
  margin-top: 0;
}

.news-list-arrows {
  display: flex;
  gap: 24px;
}

.news-list-arrows svg:hover {
  color: var(--accent);
  cursor: pointer;
}

.news-panel-important-news {
  width: 25px;
  height: 25px;
}

.news-panel-important-news img {
  width: 100%;
  height: 100%;
}

.operative-info-description p:first-child {
  margin-top: 0;
}
