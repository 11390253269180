.page-content {
  height: calc(100vh - 90px);
    overflow-y: auto;
    margin-top: 90px;
}
.page-content .breadcrumbs {
  padding: 20px 0 7px;
  font-family: var(--INTER);
  font-size: var(--standart);
  color: var(--additionalGrey);
}
.page-content .breadcrumbs-root {
  font-family: var(--INTER);
  font-size: var(--standart);
  color: var(--additionalGrey);
}
.page-content .breadcrumbs-current {
  font-family: var(--INTER);
  font-size: var(--standart);
  color: var(--main);
}
.page-content .breadcrumbs-link {
  font-family: var(--INTER);
  font-size: var(--standart);
  color: var(--additionalGrey);
  cursor: pointer;
}
.page-content .breadcrumbs-link:hover {
  color: var(--black);
} /*# sourceMappingURL=style.css.map */
