.sidebar {
  background-color: var(--bgWhite);
  border-right: 1px solid var(--border);
  min-height: 100vh;
  z-index: 1200;
}
.sidebar .sidebar-tablet-close-icon-button {
  display: none;
}
.sidebar-closed {
  min-width: var(--widthShortSideBar);
}
.sidebar-closed-logo {
  justify-content: center;
  padding-left: 10px;
}
.sidebar-opened {
  min-width: var(--widthFullSideBar);
}
.sidebar-opened-logo {
  justify-content: start;
  padding-left: 2px;
}

.logo-container {
  margin: 21px 0 21px 0;
  justify-items: center;
  cursor: pointer;
}

.logo-container.closed {
  margin: 21px 0 21px 0;
} 

.sidebar-arrow-button {
  display: block;
  transform-origin: center center;
  z-index: 1300;
  padding: 0 !important;
  color: var(--text-color) !important;
  /*margin: 16px auto 16px auto !important; */
}
.sidebar-list {
  box-shadow: none;
}
.sidebar-list.MuiAccordion-root {
  box-shadow: none;
}
.sidebar-list::before {
  height: 0 !important;
}

.sidebar-list-row {
  padding: 0 !important;
  margin: 0;
}

.sidebar-list-row:hover .sidebar-list-row-content-text {
  color: var(--hover-icon);
}
.sidebar-list-row-content {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 12px 0 12px 19px;
}

.sidebar-list-row-content.expanded {
  background-color: var(--bgListItem);
  border-radius: 32px;
}

.sidebar-list-row-content.expanded .sidebar-list-row-content-text {
  color: var(--accent);
} 

.sidebar-list-row-content-icon {
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar .MuiAccordionSummary-content {
  margin: 0;
}

.sidebar .sidebar-list-row-content-text {
  font-family: var(--INTER);
  font-size: var(--standartVeryLarge);
  font-weight: 500;
  line-height: 24px;
}

.sidebar .sidebar-list-row .Mui-expanded .expended:hover {
  color: var(--hover-icon);
}

@media screen and (max-width: 900px) {
  .sidebar .scrolled-sidebar {
    height: 87vh;
    max-height: 87vh;
  }
  .sidebar-list-row-content-icon {
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }
  .sidebar-list-row-content-icon svg {
    width: 32px;
    height: 32px;
  }
  .sidebar .sidebar-list-row-content-text {
    font-family: var(--INTER);
    font-size: var(--modalContext);
    font-weight: 500;
    line-height: 32px;
  }
  .sidebar-closed {
    min-width: 0;
    max-width: 0;
  }
  .sidebar-closed .sidebar-arrow-button {
    display: flex;
  }
  .sidebar-closed-logo {
    padding-left: 48px;
  }
  .sidebar-opened {
    min-width: 100%;
    position: fixed;
    height: 100%;
  }
  .sidebar-opened .sidebar-tablet-close-icon-button {
    display: block;
    position: fixed;
    top: 20px;
    right: 20px;
  }
  .sidebar-opened .sidebar-arrow-button {
    display: none !important;
  }
  .sidebar-opened-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-bottom: 20px;
  }
  .sidebar-opened-list {
    width: 100%;
  }
} /*# sourceMappingURL=style.css.map */
