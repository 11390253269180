.panel {
  width: 330px;
  padding: 20px 16px 24px 20px;
  display: flex;
  gap: 16px;
  border-radius: 20px;
  background-color: var(--white);
  color: var(--mainText);
}
.panel-header-wrapper {
  display: flex;
  justify-content: space-between;
}

.panel-header {
  display: flex;
  gap: 16px;
}

.panel-header-image {
  flex-shrink: 0;
  height: 64px;
  width: 64px;
}

.panel-header-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.panel-header-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.panel-header-text p {
  width: 222px;
  height: 48px;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.panel-item.delete-btn {
  color: var(--red);
  gap: 8px;
  justify-content: flex-start;
}

.panel-item.employees {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.panel-header-text svg {
  margin-top: 50px;
}

.panel-header-hint {
  color: var(--placeholder);
  font-weight: 400;
}

.panel-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;
  font-weight: 500;
  border-top: 1px solid var(--stroke);
}

.panel-item:last-child {
  padding-bottom: 1px solid var(--stroke);
}

.panel-item.bottom svg {
  transform: rotate(180);
}

.panel-elements-count {
  display: flex;
  align-items: center;
  gap: 14px;
}

.default-object-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background-color: #c0c0c0;
}

.default-object-icon svg {
  height: 50px;
  width: 50px;
}

.cursor {
  cursor: pointer;
}

.panel-risk-category {
  padding: 8px 12px;
  border-radius: 20px,
}