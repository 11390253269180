.news-modal {
    padding: 32px 50px 8px;
}

.news-modal-header {
    display: flex;
    justify-content: space-between;
}

.news-modal-header svg {
    flex-shrink: 0;
    transition: .3s;
}

.news-modal-header svg:hover {
    cursor: pointer;
    color: var(--accent);
    transition: .3s;
}

.news-modal-address {
    display: flex;
    gap: 10px;
    color: var(--headers);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
}

.news-modal-title {
    color: var(--mainText);
    font-size: 20px;
    margin-bottom: 16px;
    font-weight: 600;
}

.news-modal-btn-container {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    border-top: 1px solid #CFD9E8;
    position: sticky;
    padding: 16px 0;
    margin-top: 20px;
}

.news-modal-btn-container .right-margin svg {
    margin-right: 14px;
}

.news-modal-btn-container .left-margin svg {
    margin-left: 14px;
}

.news-modal-image {
    max-width: 1600px;
    max-height: 1080px;
}

.news-modal-image img {
    width: 100%;
    height: 100%;
}

