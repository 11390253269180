$mediumW: 1300px;
$smallW: 1050px;
$smallH: 670px;

$mobileLg: 750px;
$mobileMd: 600px;
$mobileSm: 450px;
$mobileXSm: 350px;

.equipment {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.addEquipment {
  &-steps {
    padding: 36px 0 56px;
  }

  &-image_container {
    & .sub_notification {
      padding: 10px 20px;
      border-radius: 4px 4px;
      background-color: var(--white);
      position: absolute;
      z-index: 1;

      & button {
        min-width: inherit;
        padding: 0 6px;
      }
    }

    & .column {
      @media screen and (max-width: $mobileMd) {
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
      }
    }
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;

    &-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
      padding-bottom: 15px;

      & .back_manage_equipment {
        height: 45px;
      }

      &-manage {
        height: 45px;
        border: 1px solid var(--main);
        padding: 0px 12px;
        display: flex;
        align-items: center;

        & button {
          min-width: inherit;
          padding: 0 12px;
        }
      }

      & .full_screen_icon-wrapper {
        display: flex;
        justify-content: flex-end;

        & .full_screen_icon {
          width: 45px;
          height: 45px;
          border: 1px solid var(--borderLightGrey);
          cursor: pointer;
          &:hover {
            color: var(--main);
          }
        }
      }
    }
  }

  & .addEquipment-form {
    &-section-label {
      font-family: var(--INTER);
      font-style: normal;
      font-weight: 500;
      font-size: var(--sectionTitle);
      line-height: 36px;
      color: var(--black);
      margin-bottom: 32px;
    }

    & .input-wrapper {
      & .medium_container {
        max-width: 49%;

        @media screen and (max-width: $mobileMd) {
          max-width: 100%;
        }
      }

      & .column_sm {
        @media screen and (max-width: $mobileSm) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      & .inp_number_plum {
        @media screen and (max-width: $mobileMd) {
          padding-left: 90px;
        }
      }

      & .block_with_img {
        flex-direction: row;
        gap: 15px;
        position: relative;

        &-input {
          flex-basis: 49%;

          @media screen and (max-width: $mobileMd) {
            flex-basis: 100%;
          }
        }

        &-image {
          width: 132px;
          height: 132px;
          position: absolute;
          left: 51%;
          top: -16px;

          @media screen and (max-width: $mobileMd) {
            top: 60px;
            left: 0;
            width: 76px;
            height: 76px;
          }
        }
      }

      & .input_file_block {
        @media screen and (max-width: $mobileMd) {
          flex-direction: column;
          gap: 25px;

          & .input_block_label-helper {
            width: 167px;
          }

          &-preview_field {
            justify-content: flex-start;
          }
        }
      }
    }

    & .resizing {
      flex-basis: 49%;

      @media screen and (max-width: $mobileMd) {
        flex-basis: 100%;

        & .checkbox-label {
          white-space: inherit;
          overflow: inherit;
          min-width: 235px;

          @media screen and (max-width: 300px) {
            white-space: nowrap;
            overflow: hidden;
            min-width: inherit;
          }
        }
      }
    }

    & .dynamic_form {
      flex-basis: 65%;
      padding-bottom: 0;
      padding-top: 0;

      & input,
      label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      & .under-input {
        white-space: inherit;
        overflow: inherit;
        min-width: 235px;
        min-height: inherit;
        margin-bottom: 15px;
      }

      @media screen and (max-width: 1200px) {
        flex-basis: 100%;
      }
    }

    & .dynamic_checkbox_form {
      flex-basis: 100%;

      & .checkbox-label {
        white-space: inherit;
        overflow: inherit;
        min-width: 235px;

        @media screen and (max-width: $mobileMd) {
          line-height: 18px;
        }
      }
    }

    & .block {
      width: 100%;
      padding: 14px 0 6px;

      &-title {
        font-family: var(--INTER);
        font-weight: 500;
        font-size: var(--standart);
        line-height: 24px;
        color: var(--black);
        margin-bottom: 4px;
      }

      &-form {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 49%;

        & .input_text {
          flex-basis: 50%;
          min-width: 150px;
        }

        & .adornment {
          font-family: var(--INTER);
          font-weight: 400;
          font-size: var(--standartLg);
          line-height: 24px;
          color: var(--black);
        }
      }

      &-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7px 16px 16px;
        border-bottom: 1px solid var(--bgTableHeader);

        &-wrapper-text {
          display: flex;
          align-items: center;
          gap: 5px;
        }

        &-text {
          font-family: var(--INTER);
          font-weight: 400;
          font-size: var(--standartLg);
          line-height: 24px;
          color: var(--additionalGrey);
          white-space: nowrap;
        }
      }
    }

    & .allert {
      font-family: var(--INTER);
      font-style: normal;
      font-weight: 500;
      font-size: var(--sectionTitle);
      line-height: 36px;
    }
    & .MuiAlert-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .MuiAlert-message {
      display: flex;
      align-items: center;
    }
    & .error {
      color: var(--errorText);
      background-color: var(--errorBg);
      & svg {
        fill: var(--errorText);
        width: 24px;
        height: 24px;
      }
    }

    & .success {
      color: var(--successText);
      background-color: var(--successBg);
      & svg {
        fill: var(--successText);
        width: 24px;
        height: 24px;
      }
    }
  }

  &-footer {
    display: flex;
    gap: 27px;
    justify-content: flex-end;
    padding-bottom: 10px;

    @media screen and (max-width: $mobileSm) {
      justify-content: center;
      gap: 10px;
    }

    @media screen and (max-width: 380px) {
      gap: 5px;
    }
  }
}

@media screen and (max-width: 910px) {
  .addEquipment {
    &-steps {
      position: static;
      z-index: 999;
      background-color: rgba(0, 0, 0, 0.3);
      padding: 10px;
      border-radius: 10px;

      & .stepper {
        flex-direction: row;

        & button {
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-width: 445px) {
  .addEquipment {
    &-steps {
      position: static;

      & .stepper {
        flex-direction: row;
      }
    }
  }
}

.addEquipment .schema {
  &-field {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 36px;
    margin-bottom: 54px;

    @media (max-width: 1150px) {
      flex-direction: column;
      gap: 5px;
    }

    & .droppable {
      flex-basis: 100%;
      width: 100%;
    }

    &-menu {
      max-width: 350px;
      min-width: 250px;
      border: 1px solid var(--borderLightGrey);
      border-radius: 2px;
      padding: 16px;
      flex-basis: 20%;

      @media (max-width: 1150px) {
        max-width: inherit;
        display: flex;
        gap: 15px;
        flex-basis: 100%;
        width: 97%;
      }

      @media (max-width: $mobileMd) {
        width: inherit;
      }

      @media (max-width: 445px) {
        flex-direction: column;
      }

      &-form {
        margin-bottom: 24px;

        @media (max-width: 1150px) {
          margin-bottom: 0;

          & .input-wrapper {
            width: 185px;
            margin-bottom: 0;
          }
        }

        @media (max-width: 445px) {
          & .input-wrapper {
            width: 100%;
          }
        }
      }

      &-items {
        padding: 16px;
        background: rgba(226, 232, 240, 0.4);
        border: 1px solid var(--lightGrey);
        border-radius: 2px;

        @media (max-width: 1150px) {
          display: flex;
          flex-direction: column;
          gap: 15px;
          width: 100%;
        }

        @media (max-width: 445px) {
          width: inherit;
        }

        &-title {
          font-family: var(--INTER);
          font-style: normal;
          font-weight: 400;
          font-size: var(--standartLg);
          line-height: 24px;
          color: var(--additionalGrey);
          margin-bottom: 32px;

          @media (max-width: 1150px) {
            margin-bottom: 0;
          }
        }

        &-row {
          display: flex;
          align-items: center;
          gap: 10px;

          &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: grab;
            z-index: 1000;
          }

          &-label {
            font-family: var(--INTER);
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: var(--black);
          }
        }
      }
    }

    &-image {
      position: relative;
    }

    &-image-missing {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .modal_full_screen {
      position: fixed;
      z-index: 1300;
      right: 0;
      bottom: 0;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);

      &-container {
        height: 100%;
        outline: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-modal {
        background: #ffffff;
        box-shadow: 0px 0px 8px rgba(178, 182, 203, 0.32);
        border-radius: 12px;

        &-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 28px 35px 10px;

          &-title {
            font-family: var(--INTER);
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 48px;
            color: var(--black);
          }
        }
      }
    }
  }
}

.addEquipment .plume {
  justify-content: center;
}
