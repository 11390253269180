$tabletW: 900px;

.sidebar {
  background-color: var(--bgWhite);
  border-right: 1px solid var(--grey-transparent);
  min-height: 100vh;
  z-index: 1200;

  & .sidebar-tablet-close-icon-button {
    display: none;
  }

  &-closed {
    min-width: var(--widthShortSideBar);
    &-logo {
      justify-content: center;
      padding-left: 10px;
    }
  }

  &-opened {
    min-width: var(--widthFullSideBar);
    &-logo {
      justify-content: start;
      padding-left: 2px;
    }
  }

  &-logo {
    height: var(--heighHeader);
    display: flex;
    align-items: center;
    cursor: pointer;
    column-gap: 10px;

    .logo-icon-sm {
      display: flex;
      svg {
        margin: 0;
        fill: var(--main);
        width: 50px;
        height: 53px;
      }
    }

    .logo-label-icon {
      display: flex;
      svg {
        margin: 0;
        fill: var(--main);
        width: 136px;
        height: 30px;
      }
    }
  }

  &-arrow-button {
    display: block;
    transform-origin: center center;
    z-index: 1300;
    padding: 0 !important;
    color: var(--text-color) !important;
    margin: 24px auto 44px auto !important;
  }

  &-list {
    border-right: 1px solid rgba(153, 155, 205, 0.16);
    box-shadow: none;
    &.MuiAccordion-root {
      box-shadow: none;
    }

    &::before {
      height: 0 !important;
    }
    &-row {
      margin: 0 24px !important;
      padding: 0 !important;
    }

    &-row:hover {
      border-right: 4px solid var(--main);
    }

    &-row:hover svg {
      fill: var(--hover-icon);
    }

    &-row:hover .sidebar-list-row-content-text {
      color: var(--hover-icon);
    }

    &-row {
      &-content {
        display: flex;
        align-items: center;
        gap: 10px;
        padding-top: 10px;
        padding-bottom: 10px;

        &-icon {
          width: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px 0;

          & svg {
            fill: var(--additionalGrey);
          }
        }
      }
    }
  }

  & .MuiAccordionSummary-content {
    margin: 0;
  }

  & .sidebar-list-row-content-text {
    font-family: var(--INTER);
    font-size: var(--standartLg);
    font-weight: 400;
    line-height: 24px;
  }

  & .sidebar-list-row .Mui-expanded .expended {
    color: var(--additionalGrey);
    &:hover {
      color: var(--black);
    }
  }
}

@media screen and (max-width: $tabletW) {
  .sidebar {
    .scrolled-sidebar {
      height: 87vh;
      max-height: 87vh;
    }

    &-list {
      &-row {
        &-content {
          &-icon {
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            & svg {
              width: 32px;
              height: 32px;
            }
          }
        }
      }
    }

    & .sidebar-list-row-content-text {
      font-family: var(--INTER);
      font-size: var(--modalContext);
      font-weight: 400;
      line-height: 32px;
    }

    &-closed {
      min-width: 0;
      max-width: 0;
      & .sidebar-arrow-button {
        display: flex;
      }
      &-logo {
        padding-left: 48px;
      }
    }
    &-opened {
      min-width: 100%;
      position: fixed;
      height: 100%;

      & .sidebar-tablet-close-icon-button {
        display: block;
        position: fixed;
        top: 20px;
        right: 20px;
      }

      & .sidebar-arrow-button {
        display: none !important;
      }

      &-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-bottom: 20px;
        & .logo-icon-sm {
          padding-top: 0;
          & svg {
            height: 80px;
            width: 80px;
          }
        }
        & .logo-label-icon {
          padding-top: 0;
          width: 165px;
        }
      }
      &-list {
        width: 100%;
      }
    }
  }
}
