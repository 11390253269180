.table_field {
  width: 100%;
  padding: 24px 0 0 24px;
  background: white;
  border-radius: 32px;
}
.table_field .MuiPaper-root {
  width: 100%;
  border-radius: 32px;
  box-shadow: none;
  margin-bottom: 0;
  background-color: white;
}
.table_field .table_search_form {
  max-width: 398px;
  border-radius: 4px 4px;
  margin-bottom: 12px;
}

.table .sticky {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 3;
}
.table .table-head .sticky {
  z-index: 4;
}

.table .table-head .table-head-cell.sticky {
  border-right: 1px solid var(--border) !important;
}

.table .table-head .table-head-cell {
  border: 1px solid #CFD9E8;
  color: var(--black);
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standartLg);
  background-color: var(--white);
  height: 50px;
  padding: 0 16px;
}

.table .table-head .table-head-cell .input-wrapper .MuiBox-root {
  margin-right: 0 !important;
}

.table .table-head .table-head-cell .input-wrapper .MuiInputBase-root {
  display: flex;
  justify-content: space-between;
}

.table .table-head .table-head-cell .input-wrapper {
  padding: 0 !important;
}

.table .table-head .table-head-cell:not(:last-child) {
  border-right: none;
}

.table .table-head .table-head-cell:first-child {
  border-radius: 40px 0 0 40px;
}

.table .table-head .table-head-cell .input_text label {
  margin-bottom: 0 !important;
}


.table .table-head-up_level {
  height: 40px;
  display: flex;
  align-items: center;
}
.table .table-head-up_level .standart {
  color: var(--headers);
  font-family: var(--INTER);
  font-size: var(--standart);
  font-weight: 500;
  font-size: 16px;
}
.table-body .table-row-cell {
  color: var(--black);
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standartLg);
  padding: 15px;
  border: none;
  border-bottom: 1px solid #CFD9E8;
  background-color: var(--white);
}
.table-body .table-row-cell .avatar_block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.table-body .table-row-cell .avatar_block svg {
  height: 45px;
  width: 45px;
}

.table-body .table-row-cell .avatar_block:not(:last-child) {
  margin-bottom: 16px;
}
.table-body .table-row-cell-link {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 400;
  font-size: var(--standartLg);
  line-height: 20px;
  color: var(--main);
  cursor: pointer;
}
.table-body .MuiTypography-root {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standartLg);
  line-height: 24px;
}
.table-body .point_and_text_cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7.5px;
}
.table-body .point_and_text_cell-point {
  width: 10px;
  height: 10px;
  border-radius: 5px 5px;
}
.table-body .point_and_text_cell-text {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;

  font-family: var(--INTER);
  font-style: normal;
  font-weight: 400;
  font-size: var(--standartLg);
  line-height: 20px;
}
.table-body .small_cell-text {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.table-body .bold {
  font-weight: 900;
}
.table-body .standart_lg {
  font-size: var(--standartLg);
}
.table-body .additional_grey {
  color: var(--additionalGrey);
}
.table-body .chip {
  width: 100%;;
  height: 35px;
  padding: 8px 20px;
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 500;
  font-size: var(--standartLg);
} /*# sourceMappingURL=style.css.map */
