.fire-compartment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 7px;
  padding-bottom: 25px;
}
.fire-compartment-header-buttons {
  display: flex;
  gap: 20px;
}
.fire-compartment-content-empty_text {
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--standart);
  line-height: 24px;
  color: var(--additionalGrey);
}
.fire-compartment-form .adornment {
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--standartLg);
  line-height: 24px;
  color: var(--black);
}
.fire-compartment-form .block-title {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standart);
  line-height: 24px;
  color: var(--black);
  margin-bottom: 4px;
  white-space: nowrap;
}
.fire-compartment-form .MuiAccordionSummary-content .fire-compartment-form-section-label {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: var(--black);
  padding-left: 8px;
} /*# sourceMappingURL=style.css.map */
