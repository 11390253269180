$mediumW: 1300px;
$smallW: 1050px;
$smallH: 670px;

$mobileLg: 750px;
$mobileMd: 600px;
$mobileSm: 450px;
$mobileXSm: 350px;

.confirm_modal {
  & .MuiDialog-paper {
    padding: 30px 20px;
  }

  .confirm_modal-content_field {
    padding: 0 0 30px;

    &-text {
      font-family: var(--INTER);
      font-size: var(--modalContext);
      font-weight: 500;
      color: var(--black);
      line-height: 32px;
      text-align: center;
      max-width: 420px;

      @media screen and (max-width: $mobileSm) {
        font-size: var(--titleBlock);
        line-height: 26px;
      }
    }
  }

  .confirm_modal-btn_field {
    justify-content: center;
    padding: 0;
    gap: 15px;
    & .btn {
      margin: 0;
      @media screen and (max-width: $mobileSm) {
        height: 40px;
        min-width: 120px;
      }
    }
  }
}
