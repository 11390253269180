.grid_type_tab {
  display: flex;
  padding: 18px 0;
  width: 175px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.grid_type_tab_active {
  background-color: var(--bgListItem);
  border-radius: 100px;
}
