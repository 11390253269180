.employee-select_container {
  display: flex;
  padding-left: 16px;
}
.employee .basic_form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  width: 50%;
}
.employee .basic_form .checkbox {
  margin-left: 0px;
}

@media (max-width: 1180px) {
  .employee .basic_form .contacts_form {
    flex-basis: 48%;
  }
}/*# sourceMappingURL=style.css.map */