.stepper .MuiStepLabel-iconContainer {
  cursor: pointer;
}
.stepper .MuiStepLabel-iconContainer svg {
  width: 52px;
  height: 52px;
}
.stepper .MuiStepLabel-iconContainer .Mui-active,
.stepper .MuiStepLabel-iconContainer .Mui-completed {
  fill: var(--main);
}
.stepper .MuiStepLabel-vertical .MuiStepLabel-label {
  display: none;
}
.stepper .MuiStepLabel-labelContainer .MuiStepLabel-label {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 500;
  font-size: var(--standartLg);
  line-height: 24px;
  text-align: center;
  color: var(--lightGrey);
}
.stepper .MuiStepLabel-labelContainer .Mui-active,
.stepper .MuiStepLabel-labelContainer .Mui-completed {
  color: var(--black);
} /*# sourceMappingURL=style.css.map */
