.login-wrapper {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  box-sizing: border-box;
  box-shadow: 4px 3px 16px 1px rgba(0, 0, 0, 0.29);
  height: 100vh;
}
@media screen and (max-height: 670px) {
  .login-wrapper {
    height: inherit;
  }
}
@media screen and (max-width: 1050px) {
  .login-wrapper {
    box-shadow: none;
  }
}
.login-wrapper .left_block-wrapper {
  flex-basis: 43%;
  padding: 40px 60px;
  box-sizing: border-box;
  background: #4339f2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.login-wrapper .left_block-wrapper .left_block-header {
  width: 100%;
}
.login-wrapper .left_block-wrapper .left_block-header-logo_field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-wrapper .left_block-wrapper .left_block-header-logo_field svg {
  fill: var(--white);
  max-height: 75%;
  max-width: 75%;
}
.login-wrapper .left_block-wrapper .left_block-header-logo_field img {
  width: 13%;
}
.login-wrapper .left_block-wrapper .left_block-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.login-wrapper .left_block-wrapper .left_block-body .left_block-title {
  border-bottom: 1px solid var(--white);
  flex-basis: 50%;
  display: flex;
  align-items: center;
}
.login-wrapper .left_block-wrapper .left_block-body .left_block-title .title {
  font-family: var(--INTER);
  font-size: 35px;
  font-weight: 600;
  line-height: 45px;
  color: var(--white);
  font-style: normal;
  text-align: center;
}
@media screen and (max-width: 1300px) {
  .login-wrapper .left_block-wrapper .left_block-body .left_block-title .title {
    font-size: 25px;
    line-height: 30px;
  }
}
@media screen and (max-width: 1050px) {
  .login-wrapper .left_block-wrapper .left_block-body .left_block-title .title {
    font-size: var(--modalContext);
    line-height: 28px;
  }
}
@media screen and (max-height: 670px) {
  .login-wrapper .left_block-wrapper .left_block-body .left_block-title .title {
    font-size: 21px;
    line-height: 25px;
  }
}
.login-wrapper .left_block-wrapper .left_block-body .left_block-list {
  flex-basis: 50%;
  display: flex;
  align-items: center;
}
.login-wrapper
  .left_block-wrapper
  .left_block-body
  .left_block-list-icon_wrapper {
  min-width: 80px;
}
.login-wrapper .left_block-wrapper .left_block-body .left_block-list li {
  padding-left: 0;
  padding-right: 0;
}
.login-wrapper .left_block-wrapper .left_block-body .left_block-list li span {
  font-family: var(--INTER);
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  color: var(--white);
}
@media screen and (max-width: 1300px) {
  .login-wrapper .left_block-wrapper .left_block-body .left_block-list li span {
    font-size: var(--titleBlock);
    line-height: 25px;
  }
}
@media screen and (max-width: 1050px) {
  .login-wrapper .left_block-wrapper .left_block-body .left_block-list li span {
    font-size: 12px;
    line-height: 16px;
  }
}
.login-wrapper .login-title-auth {
  font-style: normal;
  font-size: 56px;
  font-weight: 400;
  line-height: 64px;
  font-family: var(--INTER);
}
@media screen and (max-width: 1050px) {
  .login-wrapper .login-title-auth {
    font-size: 43px;
  }
}
@media screen and (max-width: 350px) {
  .login-wrapper .login-title-auth {
    font-size: 32px;
  }
}
.login-wrapper .login-title {
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
  font-family: var(--INTER);
}
.login-wrapper .default-label-text {
  font-style: normal;
  font-family: var(--INTER);
  line-height: 24px;
  font-weight: 400;
  font-size: var(--standartLg);
}
.login-wrapper .default-label-text.sm {
  font-size: 12px;
  line-height: 18px;
}
.login-wrapper .default-label-text.strong {
  font-weight: 500;
}
.login-wrapper .login-form {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
}
.login-wrapper .left_block {
  flex-basis: 43%;
  padding: 0;
  box-sizing: border-box;
  background: #4339f2;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10%;
}
@media (max-width: 750px) {
  .login-wrapper .left_block {
    display: none;
  }
}
.login-wrapper .left_block-logo {
  margin: 0 auto;
}
.login-wrapper .left_block-logo img {
  width: 180px;
}
.login-wrapper .left_block-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15%;
}
.login-wrapper .left_block-content-logo_text_field {
  display: flex;
  align-items: center;
  height: 30px;
}
.login-wrapper .left_block-content svg {
  width: 100%;
  height: 100%;
}
.login-wrapper .left_block-content-text {
  font-family: var(--INTER);
  font-size: 35px;
  font-weight: 500;
  line-height: 45px;
  color: var(--white);
  font-style: normal;
  text-align: center;
}
@media screen and (max-width: 1300px) {
  .login-wrapper .left_block-content-text {
    font-size: 25px;
    line-height: 30px;
  }
}
@media screen and (max-width: 1050px) {
  .login-wrapper .left_block-content-text {
    font-size: 21px;
    line-height: 25px;
  }
}
@media screen and (max-height: 670px) {
  .login-wrapper .left_block-content-text {
    font-size: 21px;
    line-height: 25px;
  }
}
.login-wrapper .right_block {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  padding: 2% 0 5%;
  min-width: 25%;
  max-width: 50%;
}
@media screen and (max-width: 600px) {
  .login-wrapper .right_block {
    max-width: 80%;
  }
}
.login-wrapper .right_block .registration_field {
  -moz-column-gap: 15px;
  column-gap: 15px;
}
@media screen and (max-width: 600px) {
  .login-wrapper .right_block .registration_field .btn-link {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 350px) {
  .login-wrapper .right_block .registration_field .btn-link {
    font-size: 12px;
    line-height: 12px;
  }
}
.login-wrapper .right_block .confirm_conditions {
  margin: 16px 0;
}
@media screen and (max-width: 600px) {
  .login-wrapper .right_block .confirm_conditions .MuiFormControlLabel-label {
    font-size: 13px;
  }
}
@media screen and (max-width: 450px) {
  .login-wrapper .right_block .confirm_conditions .MuiFormControlLabel-label {
    font-size: 12px;
  }
}
@media screen and (max-width: 350px) {
  .login-wrapper .right_block .confirm_conditions .MuiFormControlLabel-label {
    line-height: 12px;
  }
}
.login-wrapper .right_block .basic_information .organization_form_field {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}
.login-wrapper .right_block .basic_information .organization_form_field-ogrn {
  margin-top: 30px;
}
@media screen and (max-width: 800px) {
  .login-wrapper .right_block .basic_information .organization_form_field {
    flex-direction: column;
  }
  .login-wrapper .right_block .basic_information .organization_form_field-ogrn {
    margin-top: 0;
    width: 210px;
  }
}
.login-wrapper .right_block .basic_information .variable_width_form {
  width: 65%;
}
@media screen and (max-width: 1050px) {
  .login-wrapper .right_block .basic_information .variable_width_form {
    width: 100%;
  }
}
.login-wrapper .right_block .contacts .contacts_form_field {
  display: flex;
  justify-content: space-between;
  -moz-column-gap: 10px;
  column-gap: 10px;
  flex-wrap: wrap;
}
@media screen and (max-width: 910px) {
  .login-wrapper .right_block .contacts .contacts_form_field .input-wrapper {
    flex-basis: 100%;
  }
} /*# sourceMappingURL=style.css.map */
