.magazine_events-select_container {
  display: flex;
  padding: 0 0 14px;
  justify-content: space-between;
  align-items: center;
}
.magazine_events-select_container .input-wrapper .medium_container {
  max-width: 35%;
}
@media screen and (max-width: 600px) {
  .magazine_events-select_container .input-wrapper .medium_container {
    max-width: 100%;
  }
}
.magazine_events-select_container-btn {
  height: 49px;
}
@media screen and (max-width: 450px) {
  .magazine_events-select_container-btn {
    width: 100%;
  }
}
.magazine_events .table_header_text {
  color: var(--black);
  font-family: var(--INTER);
  font-size: var(--standart);
  font-weight: 500;
  line-height: 1.1;
}
.magazine_events .table_row_text {
  color: var(--black);
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 400;
  font-size: var(--standart);
  line-height: 16px;
} /*# sourceMappingURL=style.css.map */
