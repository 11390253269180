.signature_block {
  border: 4px solid #9a6bb7;
  padding: 5px;
  width: fit-content;

  & .block_inline {
    display: flex;
    gap: 5px;
  }

  .signature_block-text {
    font-family: var(--INTER);
    font-size: var(--standart);
    font-weight: 600;
    color: #9a6bb7;
    line-height: 17px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
