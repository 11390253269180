.controllers-container .controllers-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 7px;
}
.controllers-container .controllers-header-btns_field {
  display: flex;
  align-items: center;
  gap: 20px;
}
.controllers-container .icon-btn {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  align-items: center;
  padding: 16px;
}/*# sourceMappingURL=style.css.map */