.equipment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addEquipment{
  height: 100%;
}
.addEquipment-steps {
  padding: 36px 0 56px;
}
.addEquipment-image_container .sub_notification {
  padding: 10px 20px;
  background-color: var(--white);
  position: absolute;
  z-index: 1;
}
.addEquipment-image_container .sub_notification button {
  min-width: inherit;
  padding: 0 6px;
}

.addEquipment-image_container .sub_notification button svg {
  height: 20px;
  width: 22px;
}
@media screen and (max-width: 600px) {
  .addEquipment-image_container .column {
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
  }
}
.addEquipment-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
}
.addEquipment-wrapper-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}
.addEquipment-wrapper-buttons .back_manage_equipment {
  height: 45px;
}
.addEquipment-wrapper-buttons-manage {
  height: 46px;
  border: 1px solid #eef4fd;
  background-color: #eef4fd;
  border-radius: 32px;
  padding: 0px 24px;
  gap:16px;
  display: flex;
  align-items: center;
}
.addEquipment-wrapper-buttons-manage button {
  min-width: inherit;
  padding: 0;
}
.addEquipment-wrapper-buttons .full_screen_icon-wrapper {
  display: flex;
  justify-content: flex-end;
}
.addEquipment-wrapper-buttons .full_screen_icon-wrapper .full_screen_icon {
  width: 45px;
  height: 45px;
  border: 1px solid var(--borderLightGrey);
  cursor: pointer;
}
.addEquipment-wrapper-buttons
  .full_screen_icon-wrapper
  .full_screen_icon:hover {
  color: var(--main);
}
.addEquipment-form {
  height:calc(100vh - 284px)
}
.addEquipment .addEquipment-form-section-label {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 500;
  font-size: var(--sectionTitle);
  line-height: 36px;
  color: var(--black);
  margin-bottom: 32px;
}
.addEquipment .addEquipment-form .input-wrapper .medium_container {
  max-width: 49%;
}
@media screen and (max-width: 600px) {
  .addEquipment .addEquipment-form .input-wrapper .medium_container {
    max-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .addEquipment .addEquipment-form .input-wrapper .column_sm {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 600px) {
  .addEquipment .addEquipment-form .input-wrapper .inp_number_plum {
    padding-left: 90px;
  }
}

.addEquipment .addEquipment-form .input-wrapper .block_with_img {
  flex-direction: row;
  gap: 15px;
  position: relative;
}
.addEquipment .addEquipment-form .input-wrapper .block_with_img-input {
  flex-basis: 49%;
}
@media screen and (max-width: 600px) {
  .addEquipment .addEquipment-form .input-wrapper .block_with_img-input {
    flex-basis: 100%;
  }
}
.addEquipment .addEquipment-form .input-wrapper .block_with_img-image {
  width: 132px;
  height: 132px;
  position: absolute;
  left: 51%;
  top: -16px;
}
@media screen and (max-width: 600px) {
  .addEquipment .addEquipment-form .input-wrapper .block_with_img-image {
    top: 60px;
    left: 0;
    width: 76px;
    height: 76px;
  }
}
@media screen and (max-width: 600px) {
  .addEquipment .addEquipment-form .input-wrapper .input_file_block {
    flex-direction: column;
    gap: 25px;
  }
  .addEquipment
    .addEquipment-form
    .input-wrapper
    .input_file_block
    .input_block_label-helper {
    width: 167px;
  }
  .addEquipment
    .addEquipment-form
    .input-wrapper
    .input_file_block-preview_field {
    justify-content: flex-start;
  }
}
.addEquipment .addEquipment-form .resizing {
  flex-basis: 49%;
}
@media screen and (max-width: 600px) {
  .addEquipment .addEquipment-form .resizing {
    flex-basis: 100%;
  }
  .addEquipment .addEquipment-form .resizing .checkbox-label {
    white-space: inherit;
    overflow: inherit;
    min-width: 235px;
  }
}
@media screen and (max-width: 600px) and (max-width: 300px) {
  .addEquipment .addEquipment-form .resizing .checkbox-label {
    white-space: nowrap;
    overflow: hidden;
    min-width: inherit;
  }
}
.addEquipment .addEquipment-form .dynamic_form {
  flex-basis: 65%;
  padding-bottom: 0;
  padding-top: 0;
}
.addEquipment .addEquipment-form .dynamic_form input,
.addEquipment .addEquipment-form .dynamic_form label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.addEquipment .addEquipment-form .dynamic_form .under-input {
  white-space: inherit;
  overflow: inherit;
  min-width: 235px;
  min-height: inherit;
  margin-bottom: 15px;
}
@media screen and (max-width: 1200px) {
  .addEquipment .addEquipment-form .dynamic_form {
    flex-basis: 100%;
  }
}
.addEquipment .addEquipment-form .dynamic_checkbox_form {
  flex-basis: 100%;
}
.addEquipment .addEquipment-form .dynamic_checkbox_form .checkbox-label {
  white-space: inherit;
  overflow: inherit;
  min-width: 235px;
}
@media screen and (max-width: 600px) {
  .addEquipment .addEquipment-form .dynamic_checkbox_form .checkbox-label {
    line-height: 18px;
  }
}
.addEquipment .addEquipment-form .block {
  width: 100%;
  padding: 14px 0 6px;
}
.addEquipment .addEquipment-form .block-title {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standart);
  line-height: 24px;
  color: var(--black);
  margin-bottom: 4px;
}
.addEquipment .addEquipment-form .block-form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 49%;
}
.addEquipment .addEquipment-form .block-form .input_text {
  flex-basis: 50%;
  min-width: 150px;
}
.addEquipment .addEquipment-form .block-form .adornment {
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--standartLg);
  line-height: 24px;
  color: var(--black);
}
.addEquipment .addEquipment-form .block-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 16px 16px;
  border-bottom: 1px solid var(--bgTableHeader);
}
.addEquipment .addEquipment-form .block-footer-wrapper-text {
  display: flex;
  align-items: center;
  gap: 5px;
}
.addEquipment .addEquipment-form .block-footer-text {
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--standartLg);
  line-height: 24px;
  color: var(--additionalGrey);
  white-space: nowrap;
}
.addEquipment .addEquipment-form .allert {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 500;
  font-size: var(--sectionTitle);
  line-height: 36px;
}
.addEquipment .addEquipment-form .MuiAlert-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.addEquipment .addEquipment-form .MuiAlert-message {
  display: flex;
  align-items: center;
}
.addEquipment .addEquipment-form .error {
  color: var(--errorText);
  background-color: var(--errorBg);
}
.addEquipment .addEquipment-form .error svg {
  fill: var(--errorText);
  width: 24px;
  height: 24px;
}
.addEquipment .addEquipment-form .success {
  color: var(--successText);
  background-color: var(--successBg);
}
.addEquipment .addEquipment-form .success svg {
  fill: var(--successText);
  width: 24px;
  height: 24px;
}
.addEquipment-footer {
  display: flex;
  gap: 27px;
  justify-content: flex-end;
  padding-bottom: 10px;
}
@media screen and (max-width: 450px) {
  .addEquipment-footer {
    justify-content: center;
    gap: 10px;
  }
}
@media screen and (max-width: 380px) {
  .addEquipment-footer {
    gap: 5px;
  }
}

@media screen and (max-width: 910px) {
  .addEquipment-steps {
    position: static;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 10px;
    border-radius: 10px;
  }
  .addEquipment-steps .stepper {
    flex-direction: row;
  }
  .addEquipment-steps .stepper button {
    justify-content: center;
  }
}
@media screen and (max-width: 445px) {
  .addEquipment-steps {
    position: static;
  }
  .addEquipment-steps .stepper {
    flex-direction: row;
  }
}
.addEquipment .schema-field {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 36px;
  
}
@media (max-width: 1150px) {
  .addEquipment .schema-field {
    flex-direction: column;
    gap: 5px;
  }
}
.addEquipment .schema-field .droppable {
  flex-basis: 100%;
  width: 100%;
}
.addEquipment .schema-field-menu {
  max-width: 350px;
  min-width: 250px;
  border: 1px solid var(--borderLightGrey);
  border-radius: 2px;
  padding: 16px;
  flex-basis: 20%;
}
@media (max-width: 1150px) {
  .addEquipment .schema-field-menu {
    max-width: inherit;
    display: flex;
    gap: 15px;
    flex-basis: 100%;
    width: 97%;
  }
}
@media (max-width: 600px) {
  .addEquipment .schema-field-menu {
    width: inherit;
  }
}
@media (max-width: 445px) {
  .addEquipment .schema-field-menu {
    flex-direction: column;
  }
}
.addEquipment .schema-field-menu-form {
  margin-bottom: 24px;
}
@media (max-width: 1150px) {
  .addEquipment .schema-field-menu-form {
    margin-bottom: 0;
  }
  .addEquipment .schema-field-menu-form .input-wrapper {
    width: 185px;
    margin-bottom: 0;
  }
}
@media (max-width: 445px) {
  .addEquipment .schema-field-menu-form .input-wrapper {
    width: 100%;
  }
}
.addEquipment .schema-field-menu-items {
  padding: 16px;
  background: rgba(226, 232, 240, 0.4);
  border: 1px solid var(--lightGrey);
  border-radius: 2px;
}
@media (max-width: 1150px) {
  .addEquipment .schema-field-menu-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }
}
@media (max-width: 445px) {
  .addEquipment .schema-field-menu-items {
    width: inherit;
  }
}
.addEquipment .schema-field-menu-items-title {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 400;
  font-size: var(--standartLg);
  line-height: 24px;
  color: var(--additionalGrey);
  margin-bottom: 32px;
}
@media (max-width: 1150px) {
  .addEquipment .schema-field-menu-items-title {
    margin-bottom: 0;
  }
}
.addEquipment .schema-field-menu-items-row {
  display: flex;
  align-items: center;
  gap: 10px;
}
.addEquipment .schema-field-menu-items-row-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: -webkit-grab;
  cursor: grab;
  z-index: 1000;
}
.addEquipment .schema-field-menu-items-row-label {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--black);
}
.addEquipment .schema-field-image {
  position: relative;
}
.addEquipment .schema-field-image-missing {
  display: flex;
  align-items: center;
  justify-content: center;
}
.addEquipment .schema-field .modal_full_screen {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.addEquipment .schema-field .modal_full_screen-container {
  height: 100%;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addEquipment .schema-field .modal_full_screen-modal {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(178, 182, 203, 0.32);
  border-radius: 12px;
}
.addEquipment .schema-field .modal_full_screen-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 35px 10px;
}
.addEquipment .schema-field .modal_full_screen-modal-header-title {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: var(--black);
}

.addEquipment .plume {
  justify-content: center;
} /*# sourceMappingURL=styles.css.map */
