// it's like an example

:root {
  // Новые цвета
  --accent: #0C5389;
  --hover: #1e578d;
  --bgListItem: #eef4fd;


  --additionalGrey: #64748B;
  --black: #000000;
  --main: #4339F2;
  --main-transparent: rgba(67, 57, 242, .3);
  --grey-transparent: rgba(153, 155, 205, 0.16);
  --green: #29CA64;
  --green2: #1AB875;
  --green-transparent: rgba(16, 193, 87, .3);
  --red: #E83030;
  --red-transparent: rgba(232, 48, 48, .3);
  --white: #FFFFFF;
  --white-transparent: rgba(255,255,255, .7);
  --violet: #6C68AE;
  --lilac: #B830E8;
  --lilac-transparent: rgba(184, 48, 232, .3);
  --yellow: #EBD408;
  --yellow-transparent: rgba(235, 212, 8, .3);
  --orange-soft: #E89E30;
  --orange-soft-transparent: rgba(232, 158, 48, .3);
  --orange: #F59E0B;

  --borderLightGrey: #E2E8F0;

  --bgWhite: #FFFFFF;
  --bgHeader: #FCFDFF;
  --bgLightGrey: #F8F9FD;
  --bgGrey: #B6BDC7;
  --bgBlackGrey: #6F757D;
  --bgDarkMain: #3128C0;
  --bgTableHeader: #E2E8F0;
  --bgTableRow: #F8FAFC;
  --bgBanner: #ECEBFB;
  --c: #65C466;

  // buttons
  --main: #4339F2;
  --red: #E83030;
  --outlined: #F8F9FD;
  --additionalGrey: #64748B;
  --lightGrey: #E2E8F0;
  --lightGreyText: #B6BCC5;
  --redHover: #B90C2B;
  --mainHover: #3128C0;
  --redDisabledBg: #FDE8E9;
  --redDisabledText: #DE9F9F;

  //notification
  --successBg: #DEF7EC;
  --successBgSecondary: #DEF6EC;
  --errorBg: #DE9F9F;
  --successText: #1DA37B;
  --successTextSecondary: #1DA37B;
  --errorText: #E83030;

  //controllers
  --connectedController: #1DA37B;
  --disconnectedController: #E83030;

  //donut chart
  --overdued_jobs_amount: rgba(251, 106, 106, 1);
  --needs_attention_jobs_amount: rgba(255, 128, 0);
  --completed_jobs_amount: rgba(61, 195, 155, 1);
}