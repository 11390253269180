.lessons-bg {
    background-color: var(--white);
    padding: 32px;
    height: 780px;
    border-radius: 20px;
}

.infocenter-blocks-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 64px;
}

.header-text {
    color:#051D39;
    font-size:20px !important;
    font-weight: 500 !important;
}
.tree-header {
    color:#051D39;
    font-size:16px !important;
    font-weight: 500 !important;
}
.description-text{
    font-size: 16px;
    color:#4D5C6E;
}
.tree-box{
    border:1px solid #CFD9E8;
    border-radius: 20px;
    padding: 32px;
    margin-left:64px;
    width:460px;
}
.plyr {
    border-radius: 20px; /* Устанавливаем радиус скругления */
    overflow: hidden;
    width: 1160px; /* Убираем выступающие углы */
}
.plyr--video{
    background-color: #f8f9fd !important;
}
.plyr__video-wrapper{
    background-color: #f8f9fd !important;
}