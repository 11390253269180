.adress-filter-in-table {
    padding: 4px 16px 4px 4px;
}

.adress-filter-in-table .MuiOutlinedInput-root {
    padding-right: 0 !important;
}

.adress-filter-in-table input::placeholder {
  color: #000;
}
