.confirm_modal .MuiDialog-paper {
  padding: 30px 20px;
}
.confirm_modal .confirm_modal-content_field {
  padding: 0 0 30px;
}
.confirm_modal .confirm_modal-content_field-text {
  font-family: var(--INTER);
  font-size: var(--modalContext);
  font-weight: 500;
  color: var(--black);
  line-height: 32px;
  text-align: center;
  max-width: 420px;
}
@media screen and (max-width: 450px) {
  .confirm_modal .confirm_modal-content_field-text {
    font-size: var(--titleBlock);
    line-height: 26px;
  }
}
.confirm_modal .confirm_modal-btn_field {
  justify-content: center;
  padding: 0;
  gap: 15px;
}
.confirm_modal .confirm_modal-btn_field .btn {
  margin: 0;
}
@media screen and (max-width: 450px) {
  .confirm_modal .confirm_modal-btn_field .btn {
    height: 40px;
    min-width: 120px;
  }
} /*# sourceMappingURL=styles.css.map */
