.add-object-default-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--white);
    background-color: var(--accent);
    cursor: pointer;
    position: absolute;
}

.object-avatar {
    height: 140px;
    width: 140px;
}

.object-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.add-object-upload-file {
    position: relative;
    height: 140px;
    width: 140px;
}

.add-object-upload-file .icon_button{
    height: 40px;
    width: 40px;
    top: -5px;
    right: 5px;
}
