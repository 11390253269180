.modal_debuging {
  & .MuiDialog-paper {
    padding-bottom: 72px;
    max-height: calc(100% - 120px);
  }

  & .modal_debuging-content {
    padding: 0 44px 0 34px;
  }

  & .modal_debuging-content_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;

    @media (max-width: 490px) {
      flex-direction: column;  
      align-items: flex-start;
      gap: 0;
      padding-bottom: 10px;
    };

    &-date {
      font-size: var(--underInput);
    }

    &-message {
      color: black;
    }
  }

  & .modal_debuging-content_empty {
    width: 423px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;

    @media (max-width: 540px) {
      width: 60vw;  
    };
  }

  & .modal_debuging-content_empty_item {
    width: 423px;
    text-align: center;

    @media (max-width: 540px) {
      width: 60vw;  
    };
  }
}