.add-news {
  padding: 32px;
  background-color: var(--white);
  border-radius: 20px;
}

.add-news .add-news-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  color: var(--mainText);
}

.application-form .input-wrapper label {
  margin-left: 0;
}

.add-news-form .addNews {
  background-color: var(--accent);
}

.add-news-form .addNews:hover {
  background-color: var(--accent);
  opacity: .8;
  transition: .3s;
}

.text-editor-error .rsw-editor {
  border-color: var(--error);
}

.rdwig-container {
  font-family: var(--INTER);
}
